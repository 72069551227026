.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.veritas-background {
  background-color: #a3132a;
  width: 100vw;
  height: 100vh;
  overflow:hidden;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadeInOut {
  0% {opacity: 0;}
  50% {opacity: 1;}
  70% {opacity: 0.5;}
  100% {opacity: 0;}
}

.main-page {
  animation: fadeInOut 4s ease-in-out;
}

.fade-in {
  animation: fadeIn 2s ease-in-out forwards;
}

.chatbox-fade-in {
  animation: fadeIn 2s ease-in-out 3s forwards;
}
.fade-out {
  animation: fadeOut 2s ease-in-out forwards;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  margin-top: 20px;
  width: 100%;
  height: 100%;
}

.App-header {
  background-color: #a3132a;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logo, .title {
  animation: fadeIn 2s ease-in-out;
}

.text-width {
  width: 50vw;
  text-align: center;
}

.vertias-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  background-color: #a3132a;
  color: white;
  width: 100vw;
  height: 100vh;
}

.responses {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

.vertias-chat {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Align items to the start */
  font-size: 20px;
  background-color: #a3132a;
  color: white;
  text-align: center;
  width: 100vw;
  min-height: 100vh; /* Change this line */
  padding-top: 20px; /* Optional: add some space at the top */
}

.playfair {
  font-family: 'Playfair Display';
}

.fade-enter {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}


.fade-enter-active {
  animation: fadeIn 2s ease-in-out;
}

.chat-background {
  width: 100%;
  height: 100vh;
  padding: 20px;
  box-sizing: border-box;
}

.chat-box {
  opacity: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  background-color: #a3132a;
  color: rgb(255, 255, 255);
  width: 50vw;
  border-color: white;
  height: 40px;
  border-radius: 10px;
}

.submit-button {
  margin-top: 30px;
  opacity: 0; /* Hide the button initially */
  border-radius: 10px;
  background-color: white;
  width: 100px;
  height: 40px;
  border-color: white;
}

.speech-bubble {
  background-color: #a3132a;
  color: rgb(255, 255, 255);
  padding: 10px;
  border-radius: 10px;
  margin: 10px;
  width: 50%;
  text-align: center;
  border-width: 3px;
  border-color: white;
  border-style: solid;
}